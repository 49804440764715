<template>
  <div>
    <v-row
      align="center"
      justify="center"
      style="height: 100vh"
      class="mt-n6 mr-n16 ml-n16"
    >
      <v-col
        cols="12"
        class="
          primary
          background
          d-flex
          flex-column
          justify-center
          align-center
        "
      >
        <v-card
          class="card-login"
          width="400"
          height="450"
          color="#1a265a"
          dark
          style="opacity: 0.9"
        >
          <v-card-title class="overline text-h6 mt-4 justify-center">
            <v-img
              alt="Pedbem ADMIN"
              class="shrink mt-1"
              contain
              min-width="100"
              src="@/assets/gerentor_green.png"
              width="130"
            />
          </v-card-title>
          <v-form class="mt-8 ml-6 mr-6">
            <v-col cols="12">
              <v-text-field
                outlined
                v-model="email"
                placeholder="Usuário"
                label="Usuário"
              ></v-text-field>
            </v-col>

            <v-col cols="12" class="mt-n8">
              <v-text-field
                outlined
                v-model="password"
                placeholder="Senha"
                type="password"
                label="Senha"
              ></v-text-field>
            </v-col>

            <v-col cols="12" class="mt-n2">
              <v-btn
                @click="login"
                :loading="loadingLogin"
                color="success"
                width="100%"
                >Entrar</v-btn
              >
            </v-col>
            <!--
            <v-col cols="12" class="mt-n4">
              <v-btn
                style="text-transform: none"
                small
                text
                color="danger"
                width="100%"
                @click="openChangeDialog"
                >Esqueci Minha Senha</v-btn
              >
            </v-col>
            -->
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog width="400" v-model="recoveryPasswordDialog">
      <v-container v-if="!loadingAction">
        <v-toolbar flat color="#4c0954" dark>
          <v-toolbar-title class="overline">Recuperar Senha</v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <v-card style="padding: 16px">
          <v-card
            height="30px"
            v-if="loadingAction"
            class="d-flex justify-center"
          >
            <p>Estamos processando seus dados. Aguarde...</p></v-card
          >
          <v-form>
            <br />
            <v-row>
              <v-col cols="12" class="mt-n8">
                <v-text-field
                  outlined
                  v-model="pass.email"
                  placeholder="Email"
                  :rules="emailRules"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-card-actions class="d-flex justify-end">
              <v-row>
                <v-col cols="12" md="6">
                  <v-btn
                    block
                    :loading="loadingAction"
                    :disabled="!pass.email"
                    depressed
                    color="success"
                    @click="recoveryPassword"
                    >Solicitar</v-btn
                  >
                </v-col>

                <v-col cols="12" md="6">
                  <v-btn
                    block
                    outlined
                    depressed
                    color="primary"
                    @click="
                      recoveryPasswordDialog = false;
                      pass.email = '';
                      pass.idDocument = '';
                    "
                    >Cancelar</v-btn
                  >
                </v-col>
              </v-row>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-container>
    </v-dialog>
    <v-snackbar
      v-model="snackbar.opened"
      :bottom="snackbar.y === 'bottom'"
      :color="snackbar.color"
      :left="snackbar.x === 'left'"
      :multi-line="snackbar.mode === 'multi-line'"
      :right="snackbar.x === 'right'"
      :timeout="snackbar.timeout"
      :top="snackbar.y === 'top'"
      :vertical="snackbar.mode === 'vertical'"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.opened = false"
          >Ok</v-btn
        >
      </template>
    </v-snackbar>
  </div>
</template>

<script>
/* eslint-disable */
import http from "./../services/http";
import Vue from "vue";
export default Vue.extend({
  name: "Login",

  data: () => ({
    email: "",
    password: "",
    loadingLogin: false,
    loadingAction: false,
    recoveryPasswordDialog: false,
    pass: {
      email: "",
      idDocument: "",
    },
    emailRules: [
      (v) => !!v || "E-mail é obrigatório",
      (v) => /.+@.+\..+/.test(v) || "E-mail inválido",
    ],
    snackbar: {
      color: "",
      mode: "",
      opened: false,
      text: "",
      timeout: 6000,
      x: null,
      y: "top",
    },
  }),
  methods: {
    checkToken() {
      const token = localStorage.getItem("access_token");
      if (token) {
        this.$router.push("/");
      }
    },
    recoveryPassword() {
      this.loadingAction = true;
      http.post(`auth/recovery-password`, this.pass).then(
        () => {
          this.snackbar.text = "Uma nova SENHA foi enviada ao seu EMAIL";
          this.snackbar.color = "green";
          this.snackbar.opened = true;
          this.loadingAction = false;
          this.recoveryPasswordDialog = false;
        },
        (err) => {
          const { response } = err;
          this.snackbar.text =
            "Falha ao atualizar a senha: " + response.data?.message;
          this.snackbar.color = "red";
          this.snackbar.opened = true;
          this.loadingAction = false;
        }
      );
    },
    openChangeDialog() {
      this.recoveryPasswordDialog = true;
    },
    login() {
      this.loadingLogin = true;
      const payload = {
        email: this.email,
        password: this.password,
      };
      http.post("auth/login", payload).then(
        (data) => {
          localStorage.setItem("access_token", data.data.access_token);
          localStorage.setItem("user", JSON.stringify(data.data.user));
          this.loadingLogin = false;
          location.reload();
          this.checkToken();
        },
        (err) => {
          this.loadingLogin = false;
          alert("Credenciais Inválidas");
        }
      );
    },
  },
  mounted() {
    this.checkToken();
  },
});
</script>
<style scoped>
.background {
  background: url("https://cdn.pixabay.com/photo/2016/04/05/03/18/prayer-1308663_960_720.jpg");
  background-position: center;
  background-size: cover;

  height: 105%;
  width: 105%;
  right: -2.5%;
  left: -2.5%;
  top: -2.5%;
  bottom: -2.5%;
}
.card-login {
  width: 400px !important;
  height: 400px !important;
}
@media only screen and (max-width: 600px) {
  .card-login {
    width: 75% !important;
    height: 75% !important;
  }
}
</style>